const createLogger = (prefix: string) => {
      return {
        info: (message: string, ...args: any[]) => {
          console.log(`[${prefix}] INFO: ${message}`, ...args);
        },
        warn: (message: string, ...args: any[]) => {
          console.warn(`[${prefix}] WARN: ${message}`, ...args);
        },
        error: (message: string, ...args: any[]) => {
          console.error(`[${prefix}] ERROR: ${message}`, ...args);
        },
      };
    };

    export default createLogger;
