import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';

export default function Analytics() {
  const [data, setData] = useState({
    desempenhoCategoria: null,
    desempenhoRegional: null,
    faixaPrecoReceita: null,
    produtosCriticos: null,
    produtosMaisRentaveis: null,
    condicaoProdutos: null,
    indicadoresEstados: null,
    tendenciaTemporal: null,
    pontuacaoProdutos: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchData = async (key, endpoint) => {
    try {
      const token = localStorage.getItem('accessToken');
      if (!token) {
        throw new Error('No authentication token provided');
      }
      const response = await fetch(`/api${endpoint}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
      console.log(`Data for ${key}:`, result);
      setData((prevData) => ({ ...prevData, [key]: result }));
    } catch (error: any) {
      console.error(`Error fetching ${key}:`, error);
      setError(error.message || `Failed to fetch ${key}`);
    }
  };

  useEffect(() => {
    const fetchDataAsync = async () => {
      setLoading(true);
      setError(null);
      await Promise.all([
        fetchData('desempenhoCategoria', '/analytics/desempenho_categoria'),
        fetchData('desempenhoRegional', '/analytics/desempenho_regional'),
        fetchData('faixaPrecoReceita', '/analytics/faixa_preco_receita'),
        fetchData('produtosCriticos', '/analytics/produtos_criticos'),
        fetchData('produtosMaisRentaveis', '/analytics/produtos_mais_rentaveis'),
        fetchData('condicaoProdutos', '/analytics/condicao_produtos'),
        fetchData('indicadoresEstados', '/analytics/indicadores_estados'),
        fetchData('tendenciaTemporal', '/analytics/tendencia_temporal'),
        fetchData('pontuacaoProdutos', '/analytics/pontuacao_produtos'),
      ]);
      setLoading(false);
    };
    fetchDataAsync();
  }, []);

  const getChartOptions = (key) => {
    switch (key) {
      case 'desempenhoCategoria':
        return {
          title: { text: 'Desempenho por Categoria' },
          xAxis: { type: 'category', data: data.desempenhoCategoria?.map((d) => d.categoria) },
          yAxis: { type: 'value' },
          series: [{ data: data.desempenhoCategoria?.map((d) => d.receita_total), type: 'bar' }],
        };
      case 'faixaPrecoReceita':
        return {
          title: { text: 'Receita por Faixa de Preço' },
          legend: { top: 'bottom' },
          series: [
            {
              name: 'Receita',
              type: 'pie',
              radius: '50%',
              data: data.faixaPrecoReceita?.map((d) => ({
                value: d.receita_total,
                name: d.faixa_preco,
              })),
            },
          ],
        };
      case 'indicadoresEstados':
        return {
          title: { text: 'Indicadores por Estado' },
          xAxis: { type: 'category', data: data.indicadoresEstados?.map((d) => d.estado) },
          yAxis: { type: 'value' },
          series: [{ data: data.indicadoresEstados?.map((d) => d.receita_total), type: 'bar' }],
        };
      case 'tendenciaTemporal':
        return {
          title: { text: 'Tendência Temporal de Vendas' },
          xAxis: { type: 'category', data: data.tendenciaTemporal?.map((d) => d.mes) },
          yAxis: { type: 'value' },
          series: [{ data: data.tendenciaTemporal?.map((d) => d.receita_total), type: 'line' }],
        };
      // Adicionar mais gráficos para cada view
      default:
        return {};
    }
  };

  if (loading) {
    return (
      <div className="p-6 text-center">
        Carregando dados de analytics...
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center text-red-500">
        Erro ao carregar dados de analytics: {error}
      </div>
    );
  }

  return (
    <div className="analytics-dashboard">
      <h1 className="text-2xl font-bold">Painel de Analytics</h1>
      <div className="grid grid-cols-2 gap-4">
        {Object.keys(data).map((key) => (
          <div key={key} className="chart-container">
            {data[key] && <ReactECharts option={getChartOptions(key)} />}
          </div>
        ))}
      </div>
    </div>
  );
}
