import React from 'react';

    const WorkflowsPage = () => {
      const n8nProxyUrl = '/workflows/n8n';

      return (
        <div className="p-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Workflows</h1>
          <p className="text-gray-600 mb-4">
            Aqui você pode gerenciar seus workflows.
          </p>
          <div className="relative overflow-hidden" style={{ height: '600px' }}>
            <iframe
              src={n8nProxyUrl}
              title="n8n Workflow Editor"
              width="100%"
              height="100%"
              className="absolute inset-0"
              style={{ border: 'none' }}
            />
          </div>
        </div>
      );
    };

    export default WorkflowsPage;
