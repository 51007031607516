import axios from 'axios';
    import useAuthStore from '../store/authStore';
    import { ApiResponse } from '../types/api';

    const api = axios.create({
      baseURL: import.meta.env.VITE_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: false,
      timeout: 30000,
    });

    api.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        console.error('[Erro na Requisição]', {
          message: error.message,
          config: error.config,
          stack: error.stack,
        });
        return Promise.reject(error);
      }
    );

    api.interceptors.response.use(
      (response) => response,
      async (error) => {
        console.error('[Erro na Resposta]', {
          message: error.message,
          status: error.response?.status,
          url: error.config?.url,
          method: error.config?.method,
          data: error.response?.data,
          headers: error.response?.headers,
          requestHeaders: error.config?.headers,
        });

        if (!error.response) {
          console.error('Detalhes: Erro de conexão com o servidor');
          return Promise.reject(new Error('Erro de conexão com o servidor.'));
        }

        if (error.response.status === 401) {
          console.warn('Sessão expirada. Realizando logout.');
          localStorage.removeItem('accessToken');
          useAuthStore.getState().logout();
        }

        if (error.response.status === 405) {
          console.error('Erro 405: Método não permitido. Detalhes completos:');
          console.error('Método utilizado:', error.config?.method);
          console.error('URL solicitada:', error.config?.url);
          console.error('Payload enviado:', error.config?.data);
          console.error('Cabeçalhos da requisição:', error.config?.headers);
          console.error('Resposta do servidor:', error.response?.data);
        }

        return Promise.reject(error);
      }
    );

    export default api;
