import React, { useEffect, useState } from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import SalesOverview from './dashboard/pages/SalesOverview';
import TeamPerformance from './dashboard/pages/TeamPerformance';
import MarketingAnalytics from './dashboard/pages/MarketingAnalytics';
import ServiceQuality from './dashboard/pages/ServiceQuality';
import ModuleLoader from './ModuleLoader';
import { getKommoData } from '../api/kommoService'; // Serviço para buscar dados do Kommo CRM
import { useAuthStore } from '../store/authStore'; // Supondo que você tenha um store para autenticação

export default function Dashboard() {
  const [kommoData, setKommoData] = useState(null); // Estado para armazenar os dados do Kommo
  const [loadingKommo, setLoadingKommo] = useState(true); // Estado para indicar o carregamento
  const { role } = useAuthStore((state) => state.user); // Supondo que role esteja no estado global

  useEffect(() => {
    const fetchKommoData = async () => {
      try {
        const data = await getKommoData(); // Busca os dados do Kommo CRM
        setKommoData(data);
      } catch (error) {
        console.error('Erro ao buscar dados do Kommo CRM:', error);
        setKommoData(null); // Define como null se não houver dados
      } finally {
        setLoadingKommo(false); // Finaliza o carregamento
      }
    };

    fetchKommoData();
  }, []);

  return (
    <div className="w-full">
      <Tabs defaultValue="overview" className="w-full">
        <div className="border-b px-6">
          <TabsList className="flex space-x-8">
            <TabsTrigger value="overview" className="pb-4 text-sm font-medium">
              Visão Geral
            </TabsTrigger>
            <TabsTrigger value="team" className="pb-4 text-sm font-medium">
              Performance da Equipe
            </TabsTrigger>
            <TabsTrigger value="marketing" className="pb-4 text-sm font-medium">
              Analytics de Marketing
            </TabsTrigger>
            <TabsTrigger value="quality" className="pb-4 text-sm font-medium">
              Qualidade do Atendimento
            </TabsTrigger>
            {role === 'ADMIN' && (
              <TabsTrigger value="modules" className="pb-4 text-sm font-medium">
                Módulos Habilitados
              </TabsTrigger>
            )}
          </TabsList>
        </div>

        <TabsContent value="overview">
          <SalesOverview />
        </TabsContent>

        <TabsContent value="team">
          <TeamPerformance />
        </TabsContent>

        <TabsContent value="marketing">
          {loadingKommo ? (
            <div className="p-6 text-center">Carregando dados do Kommo CRM...</div>
          ) : kommoData ? (
            <MarketingAnalytics data={kommoData} />
          ) : (
            <div className="p-6 text-center text-gray-500">
              <p>Nenhum dado disponível no Kommo CRM.</p>
              <p>Certifique-se de que a integração está configurada corretamente ou tente novamente mais tarde.</p>
            </div>
          )}
        </TabsContent>

        <TabsContent value="quality">
          <ServiceQuality />
        </TabsContent>

        <TabsContent value="modules">
          <ModuleLoader />
        </TabsContent>
      </Tabs>
    </div>
  );
}
