import React, { useState, useRef, useEffect } from 'react';
    import { Bell, Settings, User, LogOut, ChevronDown } from 'lucide-react';
    import { useNavigate } from 'react-router-dom';
    import useAuthStore from '../store/authStore';
    import { cn } from '../lib/utils';

    export default function Navbar() {
      const navigate = useNavigate();
      const { user, logout } = useAuthStore();
      const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
      const [isSettingsOpen, setIsSettingsOpen] = useState(false);
      const [isProfileOpen, setIsProfileOpen] = useState(false);
      const notificationsRef = useRef<HTMLDivElement>(null);
      const settingsRef = useRef<HTMLDivElement>(null);
      const profileRef = useRef<HTMLDivElement>(null);

      const handleLogout = () => {
        logout();
        navigate('/login');
      };

      const handleSettingsClick = () => {
        navigate('/settings');
      };

      const toggleNotifications = () => {
        setIsNotificationsOpen(!isNotificationsOpen);
      };

      const toggleSettings = () => {
        setIsSettingsOpen(!isSettingsOpen);
      };

      const toggleProfile = () => {
        setIsProfileOpen(!isProfileOpen);
      };

      // Close dropdowns when clicking outside
      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (notificationsRef.current && !notificationsRef.current.contains(event.target as Node)) {
            setIsNotificationsOpen(false);
          }
          if (settingsRef.current && !settingsRef.current.contains(event.target as Node)) {
            setIsSettingsOpen(false);
          }
          if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
            setIsProfileOpen(false);
          }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
      }, []);

      return (
        <nav className="bg-white border-b border-gray-200 fixed w-full z-30">
          <div className="max-w-full mx-auto px-4 lg:px-6">
            <div className="flex justify-between h-16">
              {/* Logo/Brand - Ajustado para considerar o menu móvel */}
              <div className="flex items-center">
                <div className="flex items-center ml-0 lg:ml-4">
                  <span className="text-2xl font-bold text-indigo-600 ml-12 lg:ml-0">Integro</span>
                </div>
              </div>
              
              {/* Right side items */}
              <div className="flex items-center space-x-2 sm:space-x-4">
                <div className="relative" ref={notificationsRef}>
                  <button onClick={toggleNotifications} className="p-2 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100 hidden sm:block">
                    <Bell className="h-5 w-5" />
                  </button>
                  {isNotificationsOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                      <div className="p-2">
                        <p className="text-sm text-gray-600">Nenhuma notificação</p>
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative" ref={settingsRef}>
                  <button onClick={toggleSettings} className="p-2 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100 hidden sm:block">
                    <Settings className="h-5 w-5" />
                  </button>
                  {isSettingsOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                      <div className="p-2">
                        <button
                          onClick={handleSettingsClick}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Configurações
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                
                {/* User menu */}
                <div className="relative" ref={profileRef}>
                  <button onClick={toggleProfile} className="flex items-center space-x-2 p-2 rounded-full text-gray-600 hover:text-gray-900 hover:bg-gray-100">
                    <User className="h-5 w-5" />
                    <span className="hidden md:block text-sm font-medium">
                      {user?.name || 'User'}
                    </span>
                    <ChevronDown className="h-4 w-4 ml-1" />
                  </button>
                  {isProfileOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-lg shadow-lg z-50">
                      <div className="p-2">
                        <button
                          onClick={handleLogout}
                          className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sair
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
      );
    }
