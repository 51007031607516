import axios from 'axios';

const apiUrl = '/api/modules';

// Obter todos os módulos
export const getModules = async () => {
    try {
        const response = await axios.get(apiUrl);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar módulos:', error);
        throw error;
    }
};

// Criar um novo módulo
export const createModule = async (moduleData) => {
    try {
        const response = await axios.post(apiUrl, moduleData);
        return response.data;
    } catch (error) {
        console.error('Erro ao criar módulo:', error);
        throw error;
    }
};

// Atualizar um módulo
export const updateModule = async (id, moduleData) => {
    try {
        const response = await axios.put(`${apiUrl}/${id}`, moduleData);
        return response.data;
    } catch (error) {
        console.error('Erro ao atualizar módulo:', error);
        throw error;
    }
};

// Deletar um módulo
export const deleteModule = async (id) => {
    try {
        await axios.delete(`${apiUrl}/${id}`);
    } catch (error) {
        console.error('Erro ao deletar módulo:', error);
        throw error;
    }
};
