import React, { useEffect, useState } from 'react';
import { getModules } from '../api/moduleService';

const ModuleLoader = () => {
  const [modules, setModules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchModules = async () => {
      try {
        const userModules = await getModules(); // Chamada à API para buscar os módulos
        setModules(userModules);
      } catch (err) {
        setError('Erro ao carregar os módulos habilitados.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchModules();
  }, []);

  if (loading) {
    return <p>Carregando módulos...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="module-container">
      <h2 className="text-xl font-bold">Módulos Habilitados</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {modules.map((module) => (
          <div
            key={module.id}
            className="p-4 border rounded shadow-md bg-white hover:shadow-lg transition-shadow"
          >
            <h3 className="text-lg font-semibold">{module.name}</h3>
            <p className="text-gray-600">{module.slug}</p>
            <p className="text-sm text-gray-500">Configurações: {JSON.stringify(module.config)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModuleLoader;
