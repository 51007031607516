import React, { useState, useEffect } from 'react';
import { getModules, createModule, updateModule, deleteModule } from '../api/moduleService';

const ModuleManager = () => {
    const [modules, setModules] = useState([]);
    const [newModule, setNewModule] = useState({ name: '', slug: '', config: {} });
    const [editModule, setEditModule] = useState(null);

    useEffect(() => {
        const fetchModules = async () => {
            try {
                const data = await getModules();
                setModules(Array.isArray(data) ? data : []); // Garante que seja um array
            } catch (error) {
                console.error('Erro ao carregar módulos:', error);
            }
        };
        fetchModules();
    }, []);

    const handleCreate = async () => {
        try {
            const module = await createModule(newModule);
            setModules([...modules, module]);
            setNewModule({ name: '', slug: '', config: {} });
        } catch (error) {
            console.error('Erro ao criar módulo:', error);
        }
    };

    const handleUpdate = async (id) => {
        try {
            const updated = await updateModule(id, editModule);
            setModules(modules.map((mod) => (mod.id === id ? updated : mod)));
            setEditModule(null);
        } catch (error) {
            console.error('Erro ao atualizar módulo:', error);
        }
    };

    const handleDelete = async (id) => {
        try {
            await deleteModule(id);
            setModules(modules.filter((mod) => mod.id !== id));
        } catch (error) {
            console.error('Erro ao deletar módulo:', error);
        }
    };

    useEffect(() => {
        document.title = editModule ? 'Editando Módulo' : 'Gerenciador de Módulos';
    }, [editModule]);

    return (
        <div className="min-h-screen bg-gray-50 py-10 px-5 sm:px-10">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">Gerenciador de Módulos</h1>

                {/* Seção de criação */}
                <div className="bg-white shadow rounded-lg p-6 mb-8">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">Criar Novo Módulo</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <input
                            type="text"
                            placeholder="Nome"
                            value={newModule.name}
                            onChange={(e) => setNewModule({ ...newModule, name: e.target.value })}
                            className="border border-gray-300 rounded-lg p-2"
                        />
                        <input
                            type="text"
                            placeholder="Slug"
                            value={newModule.slug}
                            onChange={(e) => setNewModule({ ...newModule, slug: e.target.value })}
                            className="border border-gray-300 rounded-lg p-2"
                        />
                    </div>
                    <button
                        onClick={handleCreate}
                        className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-lg hover:bg-indigo-700 transition duration-300"
                    >
                        Criar
                    </button>
                </div>

                {/* Lista de módulos */}
                <div className="bg-white shadow rounded-lg p-6">
                    <h2 className="text-xl font-semibold text-gray-700 mb-4">Módulos Existentes</h2>
                    <div className="space-y-4">
                        {modules.length > 0 ? (
                            modules.map((module) => (
                                <div
                                    key={module.id}
                                    className="flex flex-col sm:flex-row items-start sm:items-center justify-between bg-gray-100 rounded-lg p-4 shadow-sm"
                                >
                                    {editModule?.id === module.id ? (
                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full">
                                            <input
                                                type="text"
                                                value={editModule.name}
                                                onChange={(e) =>
                                                    setEditModule({ ...editModule, name: e.target.value })
                                                }
                                                className="border border-gray-300 rounded-lg p-2 flex-1"
                                            />
                                            <input
                                                type="text"
                                                value={editModule.slug}
                                                onChange={(e) =>
                                                    setEditModule({ ...editModule, slug: e.target.value })
                                                }
                                                className="border border-gray-300 rounded-lg p-2 flex-1"
                                            />
                                            <button
                                                onClick={() => handleUpdate(module.id)}
                                                className="bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 transition duration-300"
                                            >
                                                Salvar
                                            </button>
                                            <button
                                                onClick={() => setEditModule(null)}
                                                className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition duration-300"
                                            >
                                                Cancelar
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-4 w-full">
                                            <div className="flex-1">
                                                <p className="font-semibold text-gray-800">{module.name}</p>
                                                <p className="text-gray-600 text-sm">{module.slug}</p>
                                            </div>
                                            <button
                                                onClick={() => setEditModule(module)}
                                                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition duration-300"
                                            >
                                                Editar
                                            </button>
                                            <button
                                                onClick={() => handleDelete(module.id)}
                                                className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 transition duration-300"
                                            >
                                                Deletar
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p className="text-gray-600">Nenhum módulo encontrado.</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModuleManager;
