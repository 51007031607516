import React, { useState } from 'react';
    import { CheckCircle, AlertTriangle } from 'lucide-react';
    import api from '../lib/api';

    export default function Settings() {
      const [name, setName] = useState('Nome do Usuário');
      const [email, setEmail] = useState('email@example.com');
      const [password, setPassword] = useState('');
      const [notificationsEnabled, setNotificationsEnabled] = useState(true);
      const [theme, setTheme] = useState('light');
      const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error'>('idle');
      const [saveMessage, setSaveMessage] = useState<string | null>(null);
      const [loading, setLoading] = useState(false);

      const handleSave = async () => {
        setSaveStatus('idle');
        setSaveMessage(null);
        setLoading(true);

        try {
          const settingsData = {
            name,
            email,
            password,
            notificationsEnabled,
            theme,
          };

          // Make API call to save settings
          const response = await api.post('/auth/settings', settingsData);

          if (response.data.status === 'success') {
            setSaveStatus('success');
            setSaveMessage('Configurações salvas com sucesso!');
          } else {
            setSaveStatus('error');
            setSaveMessage(response.data.message || 'Erro ao salvar configurações. Tente novamente.');
          }
        } catch (error: any) {
          setSaveStatus('error');
          setSaveMessage(error.message || 'Erro ao salvar configurações. Tente novamente.');
        } finally {
          setLoading(false);
        }
      };

      return (
        <div className="p-6 space-y-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Configurações</h1>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Profile Settings */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Perfil</h2>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Nome</label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Nova Senha</label>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                  />
                </div>
              </div>
            </div>

            {/* Notification Settings */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Notificações</h2>
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={notificationsEnabled}
                  onChange={() => setNotificationsEnabled(!notificationsEnabled)}
                  className="h-4 w-4 text-indigo-600 rounded border-gray-300"
                />
                <label className="text-sm text-gray-700">Habilitar notificações</label>
              </div>
            </div>

            {/* Appearance Settings */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Aparência</h2>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-700 mb-1">Tema</label>
                <select
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md text-sm"
                >
                  <option value="light">Claro</option>
                  <option value="dark">Escuro</option>
                </select>
              </div>
            </div>

            {/* Account Settings */}
            <div className="bg-white rounded-xl shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Conta</h2>
              <button className="text-red-600 hover:text-red-700">
                Deletar Conta
              </button>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleSave}
              disabled={loading}
              className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Salvando...' : 'Salvar Configurações'}
            </button>
          </div>

          {saveStatus === 'success' && (
            <div className="mt-4 p-3 bg-green-50 border border-green-200 rounded-lg text-sm text-green-600 flex items-center">
              <CheckCircle className="w-4 h-4 mr-2" />
              {saveMessage}
            </div>
          )}
          {saveStatus === 'error' && (
            <div className="mt-4 p-3 bg-red-50 border border-red-200 rounded-lg text-sm text-red-600 flex items-center">
              <AlertTriangle className="w-4 h-4 mr-2" />
              {saveMessage}
            </div>
          )}
        </div>
      );
    }
