import React, { useEffect, useState } from 'react';
    import { CheckCircle2, AlertTriangle, XCircle } from 'lucide-react';
    import api from '../../../utils/api'; // Configuração do Axios centralizado

    export default function KommoConnectionStatus() {
      const [kommoConfig, setKommoConfig] = useState({
        isConnected: false,
        accountDomain: null,
        createdAt: null,
        expiresAt: null,
        error: null,
      });

      useEffect(() => {
        // Buscar os dados de configuração do Kommo
        api.get('/integrations/kommo/config')
          .then((response) => {
            if (response.data?.data) {
              const { accountDomain, createdAt, expiresAt } = response.data.data;

              setKommoConfig({
                isConnected: !!accountDomain, // Considera conectado se houver domínio configurado
                accountDomain,
                createdAt: createdAt ? new Date(createdAt).toLocaleString() : 'Never',
                expiresAt: expiresAt ? new Date(expiresAt).toLocaleString() : 'Unknown',
                error: null,
              });
            } else {
              setKommoConfig({
                isConnected: false,
                accountDomain: null,
                createdAt: null,
                expiresAt: null,
                error: 'Invalid response from server',
              });
            }
          })
          .catch((err) => {
            console.error('Erro ao buscar configuração do Kommo:', err);
            setKommoConfig({
              isConnected: false,
              accountDomain: null,
              createdAt: null,
              expiresAt: null,
              error: 'Failed to load Kommo configuration.',
            });
          });
      }, []);

      const getStatusColor = () => {
        if (kommoConfig.error) return 'text-red-600';
        if (kommoConfig.isConnected) return 'text-green-600';
        return 'text-yellow-600';
      };

      const getStatusIcon = () => {
        if (kommoConfig.error) return <XCircle className="h-5 w-5 mr-1" />;
        if (kommoConfig.isConnected) return <CheckCircle2 className="h-5 w-5 mr-1" />;
        return <AlertTriangle className="h-5 w-5 mr-1" />;
      };

      const getStatusText = () => {
        if (kommoConfig.error) return 'Connection Error';
        if (kommoConfig.isConnected) return 'Connected';
        if (kommoConfig.accountDomain) return 'Reconnection Required';
        return 'Not Connected';
      };

      const getStatusMessage = () => {
        if (kommoConfig.error) return kommoConfig.error;
        if (!kommoConfig.accountDomain) return 'Please connect your Kommo account';
        if (!kommoConfig.isConnected) return 'Your connection needs to be updated. Please reconnect.';
        return null;
      };

      return (
        <div className="mb-4">
          <div className={`flex items-center ${getStatusColor()}`}>
            {getStatusIcon()}
            <span className="text-sm font-medium">{getStatusText()}</span>
          </div>
          
          {kommoConfig.accountDomain && (
            <div className="mt-2 text-xs text-gray-500">
              <p>Conta: {kommoConfig.accountDomain}</p>
              <p>Conectado em: {kommoConfig.createdAt}</p>
              <p>Expira em: {kommoConfig.expiresAt}</p>
            </div>
          )}

          {getStatusMessage() && (
            <div className="mt-2 p-2 bg-red-50 rounded-lg">
              <p className="text-xs text-red-600">
                {getStatusMessage()}
              </p>
            </div>
          )}
        </div>
      );
    }
