import axios from 'axios';

export const getKommoData = async () => {
  try {
    const response = await axios.get('/api/kommo/data'); // Certifique-se de que o proxy no Vite está configurado corretamente
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      console.warn('Token não fornecido ou inválido.');
      return null; // Retorna null se o token estiver ausente ou inválido
    }

    console.error('Erro ao buscar dados do Kommo CRM:', error.message);
    throw error; // Repassa outros erros para tratamento superior
  }
};
