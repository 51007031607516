import { useEffect, useState } from 'react';
    import api from '../lib/api';

    export interface KommoConfig {
      accountDomain: string | null;
      clientId: string | null;
      connectedAt: string | null;
    }

    export interface KommoStatus {
      isConnected: boolean;
      config: KommoConfig | null;
      error: string | null;
    }

    export function useKommoIntegration() {
      const [status, setStatus] = useState<KommoStatus>({
        isConnected: false,
        config: null,
        error: null,
      });

      const [isLoading, setIsLoading] = useState<boolean>(true);

      // Fetch Kommo configuration on mount
      useEffect(() => {
        fetchKommoConfig();
      }, []);

      const fetchKommoConfig = async () => {
        setIsLoading(true);
        try {
          const response = await api.get('/integrations/kommo/config');
          if (response.data?.data) {
            const { accountDomain, clientId, createdAt } = response.data.data;
            setStatus({
              isConnected: !!response.data.data.accessToken,
              config: {
                accountDomain,
                clientId,
                connectedAt: createdAt,
              },
              error: null,
            });
          } else {
            setStatus({
              isConnected: false,
              config: null,
              error: 'Invalid response from server',
            });
          }
        } catch (err: any) {
          console.error('Erro ao buscar configuração do Kommo:', err);
          setStatus({
            isConnected: false,
            config: null,
            error: err.response?.data?.message || 'Failed to fetch Kommo configuration.',
          });
        } finally {
          setIsLoading(false);
        }
      };

      const initiateOAuth = async ({ accountDomain, redirectUri }: { accountDomain: string; redirectUri: string }) => {
        try {
          const response = await api.post('/kommo/auth/init', { accountDomain, redirectUri });
          return response.data;
        } catch (err: any) {
          console.error('Erro ao iniciar OAuth:', err);
          throw new Error(err.response?.data?.message || 'Failed to initiate OAuth.');
        }
      };

      const disconnect = async () => {
        try {
          await api.delete('/integrations/kommo/config');
          setStatus({
            isConnected: false,
            config: null,
            error: null,
          });
        } catch (err: any) {
          console.error('Erro ao desconectar do Kommo:', err);
          throw new Error(err.response?.data?.message || 'Failed to disconnect from Kommo.');
        }
      };

      const refresh = async () => {
        await fetchKommoConfig();
      };

      return {
        isConnected: status.isConnected,
        isLoading,
        error: status.error,
        config: status.config,
        initiateOAuth,
        disconnect,
        refresh,
      };
    }
