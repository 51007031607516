import React from 'react';
    import { DollarSign, TrendingUp, Users, ShoppingBag } from 'lucide-react';
    import StatCard from '../StatCard';
    import LeadsList from '../LeadsList';
    import DaySelector from '../DaySelector';
    import Skeleton from '../../Skeleton';
    import { useDashboardData } from '../../../hooks/useDashboardData';
    import { useDashboardStore } from '../../../store/dashboardStore';
    import { DateRange } from '../../../types/dashboard';
    import { SALE_STATUSES } from '../../../lib/kommo/constants';
    import { formatCurrency } from '../../../utils/leadUtils';
    import PurchaseStats from '../PurchaseStats';

    export default function SalesOverview() {
      const { selectedDate, setSelectedDate } = useDashboardStore();
      const { data, loading, error, isConnected } = useDashboardData();

      const handleDateChange = (newDate: DateRange) => {
        setSelectedDate(newDate);
      };

      if (loading) {
        return (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
            {Array(4).fill(0).map((_, index) => (
              <Skeleton key={index} className="h-24" />
            ))}
          </div>
        );
      }

      if (error || !data?.currentStats) {
        return (
          <div className="p-6 text-center text-gray-500">
            <p>Erro ao carregar dados. Por favor, tente novamente.</p>
            <p>{error?.message || 'Detalhes indisponíveis.'}</p>
          </div>
        );
      }

      const { currentStats, comparisonStats } = data;
      const totalLeads = currentStats.leads.length;
      const sales = currentStats.leads.filter(lead => SALE_STATUSES.includes(lead.status_id));
      const totalValue = sales.reduce((sum, lead) => sum + (parseFloat(lead.valor) || 0), 0);

      const statsConfig = [
        {
          title: "Receita Total",
          value: formatCurrency(totalValue),
          change: comparisonStats?.valorTotal,
          icon: DollarSign,
          color: "green",
          subtitle: `${sales.length} vendas realizadas`
        },
        {
          title: "Ticket Médio",
          value: sales.length > 0 ? formatCurrency(totalValue / sales.length) : "R$ 0,00",
          icon: ShoppingBag,
          color: "blue",
          subtitle: "Por venda"
        },
        {
          title: "Taxa de Conversão",
          value: totalLeads > 0 ? `${((sales.length / totalLeads) * 100).toFixed(1)}%` : "0%",
          icon: TrendingUp,
          color: "indigo",
          subtitle: `De ${totalLeads} leads totais`
        },
        {
          title: "Leads Ativos",
          value: totalLeads,
          icon: Users,
          color: "purple",
          subtitle: `${currentStats.leads.filter(l => !l.vendedor).length} não atribuídos`
        }
      ];

      return (
        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
            <div>
              <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Visão Geral de Vendas</h1>
              <p className="text-sm sm:text-base text-gray-500">
                Acompanhamento em tempo real do desempenho comercial
                {!isConnected && (
                  <span className="text-red-500"> (Reconectando...)</span>
                )}
              </p>
            </div>
            <DaySelector value={selectedDate} onChange={handleDateChange} />
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
            {statsConfig.map((stat, index) => (
              <StatCard key={index} {...stat} />
            ))}
          </div>

          <div className="bg-gray-50 rounded-xl">
            <div className="p-4 sm:p-6 border-b border-gray-200">
              <h2 className="text-lg font-semibold text-gray-900">Leads do Período</h2>
            </div>
            <LeadsList leads={currentStats.leads.map(lead => ({
              id: lead.id,
              nome: lead.nome,
              status: lead.status,
              vendedor: lead.vendedor || 'Não atribuído',
              valor: lead.valor,
              created_at: lead.created_at
            }))} />
          </div>

          {data?.kommoAnalytics && (
            <PurchaseStats
              total={totalValue}
              byProduct={Object.entries(data.kommoAnalytics.purchases.reduce((acc, purchase) => {
                purchase.products.forEach(product => {
                  acc[product.name] = acc[product.name] || { count: 0, value: 0 };
                  acc[product.name].count += product.quantity;
                  acc[product.name].value += product.price * product.quantity;
                });
                return acc;
              }, {}))}
              byPayment={Object.entries(data.kommoAnalytics.purchases.reduce((acc, purchase) => {
                acc[purchase.paymentMethod] = acc[purchase.paymentMethod] || { count: 0, value: 0 };
                acc[purchase.paymentMethod].count++;
                acc[purchase.paymentMethod].value += purchase.totalAmount;
                return acc;
              }, {}))}
              byPersona={Object.entries(data.kommoAnalytics.purchases.reduce((acc, purchase) => {
                acc[purchase.persona] = acc[purchase.persona] || { count: 0, value: 0 };
                acc[purchase.persona].count++;
                acc[purchase.persona].value += purchase.totalAmount;
                return acc;
              }, {}))}
            />
          )}
        </div>
      );
    }
